import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IconContext } from "react-icons";

import {
  CssBaseline,
  Toolbar,
  AppBar,
  Button as MuiButton,
  IconButton,
  Stack,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { CgMenuLeftAlt as MenuIcon } from "react-icons/cg";
import UserAvatar from "./common/UserAvatar";

import { theme } from "../context/ThemeProvider";
import { logout } from "../redux/actions/AuthActions";

function Header(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authenticatedUser = useSelector(
    (state) =>
      state.AuthReducer.userProfile ||
      JSON.parse(localStorage.getItem("user_profile"))
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);

  const toggleProfileMenu = (show) => (event) => {
    if (show) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const toggleProfileMenu2 = (show) => (event) => {
    if (show) {
      setAnchorEl2(event.currentTarget);
    } else {
      setAnchorEl2(null);
    }
  };

  const handleLogout = (event) => {
    dispatch(logout(navigate));
    toggleProfileMenu(false)(event);
  };

  const switchToAgro = (event) => {
    toggleProfileMenu2(false)(event);
    navigate("/dashboard");
    document.title = "Agro";
    localStorage.setItem("current_app", "agro");
  };

  const switchToCapture = (event) => {
    toggleProfileMenu2(false)(event);
    navigate("/dashboard-capture/overview");
    document.title = "Capture";
    localStorage.setItem("current_app", "capture");
  };

  const switchToCropGate = (event) => {
    toggleProfileMenu2(false)(event);
    navigate("/dashboard-crop-gate/overview");
    document.title = "CropGate";
    localStorage.setItem("current_app", "crop-gate");
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="relative" elevation={0} color="secondary">
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
          }}
        >
          <div>
            <MuiButton
              sx={{ padding: "10px 15px 10px 20px" }}
              id="header-profile-button"
              aria-controls={open ? "header-profile-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={toggleProfileMenu(true)}
            >
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  overflow: "hidden",
                }}
              >
                <UserAvatar
                  size="32px"
                  fontSize="13px"
                  firstname={authenticatedUser?.first_name || ""}
                  lastname={authenticatedUser?.last_name || ""}
                />
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  sx={{ ml: "13px", textTransform: "capitalize" }}
                >
                  <h3
                    style={{ margin: "0", fontWeight: "800", fontSize: "14px" }}
                  >
                    {`${authenticatedUser?.first_name || "-"} ${
                      authenticatedUser?.last_name || "-"
                    }`}
                  </h3>
                  <span style={{ fontSize: "12px" }}>
                    {authenticatedUser?.myRole?.role || "-"}
                  </span>
                </Stack>
              </Stack>
            </MuiButton>
            <Menu
              id="header-profile-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={toggleProfileMenu(false)}
              MenuListProps={{
                "aria-labelledby": "header-profile-button",
              }}
              sx={{
                "& .MuiMenu-paper": {
                  borderRadius: "15px",
                  boxShadow: "0px 5px 20px rgba(108, 117, 125, 0.4)",
                },
              }}
            >
              <MenuItem onClick={toggleProfileMenu(false)}>
                <MuiButton
                  href="/profile"
                  sx={{
                    p: 0,
                    m: 0,
                    textTransform: "capitalize",
                    color: "inherit",
                    fontSize: "inherit",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  Edit Profile
                </MuiButton>
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>

            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={toggleProfileMenu2(true)}
              // sx={{ ml: -1, display: { md: "none" } }}
            >
              {/* <MenuIcon /> */}
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="header-profile-menu-2"
              anchorEl={anchorEl2}
              open={open2}
              onClose={toggleProfileMenu2(false)}
              MenuListProps={{
                "aria-labelledby": "header-profile-button",
              }}
              sx={{
                "& .MuiMenu-paper": {
                  borderRadius: "15px",
                  boxShadow: "0px 5px 20px rgba(108, 117, 125, 0.4)",
                },
              }}
            >
              <MenuItem onClick={toggleProfileMenu2(false)}></MenuItem>
              <MenuItem onClick={switchToAgro}>Agro</MenuItem>
              <MenuItem onClick={switchToCapture}>Capture</MenuItem>
              <MenuItem onClick={switchToCropGate}>CropGate</MenuItem>
            </Menu>
          </div>
          <IconContext.Provider
            value={{ size: "28px", color: `${theme.palette.primary.main}` }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={props.toggler}
              sx={{ ml: -1, display: { md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          </IconContext.Provider>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

export default Header;
