export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const AUTH_URL = process.env.REACT_APP_AUTH_URL;

const config = {
    // BASE_URL: process.env.REACT_APP_BASE_URL,
    mapsKey: process.env.REACT_APP_MAP_KEY,
    // AUTH_URL: process.env.REACT_APP_AUTH_URL,
    weatherKey: process.env.REACT_APP_WEATHER_KEY,
    opeIssue: process.env.REACT_APP_OPE_ISSUE_URL,
}

export default config;