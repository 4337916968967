import axios from "axios";
// import * as ReactRouter from 'react-router-dom'
import { AUTH_URL } from "../../configs/api";
import axiosServices from "../../services/axiosServices";
import { errorHandler } from "../../utils/actionsErrorHandler";
import rules from "../../utils/rbac/rules";
import { notify } from "../../utils/toastNotification";
import { SET_PERMISSIONS } from "../types/AppActionTypes";
// import { useQuery } from "../../utils/useQuery";
import {
  GET_PROFILE_FAIL,
  GET_PROFILE_SUCCESS,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOGIN_LOADING,
} from "../types/AuthTypes";

let tempUserProfile = {};
// eslint-disable-next-line
export const getUserProfile = () => async (dispatch) => {
  try {
    const res = await axiosServices.get("/v3/user");
    // console.log({ress: res});
    const userData = res?.result?.user;
    if (userData) {
      dispatch({
        type: GET_PROFILE_SUCCESS,
        payload: userData,
      });
      tempUserProfile = userData;
    } else {
      dispatch({
        type: GET_PROFILE_FAIL,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_PROFILE_FAIL,
    });
    return error;
  }
};

export const login = (data, navigate) => async (dispatch) => {
  try {
    dispatch({
      type: LOGIN_LOADING,
    });
    const res = await axios.post(`${AUTH_URL}/login`, data);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res,
    });
    // console.log("RES", res);
    notify("Login Successful", { type: "success" });

    await dispatch(getUserProfile());

    // const query = useQuery();
    navigate(
      !rules[tempUserProfile?.myRole?.role]?.landing
        ? "/dashboard"
        : rules[tempUserProfile?.myRole?.role]?.landing
    );
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
    });
    errorHandler(error, "Login Failed");
    // notify(, { type: "error" });
    return error;
  }
};

export const getAllPermission = () => async (dispatch) => {
  try {
    const response = await axiosServices.get(
      `/v3/permissions?groupPermission=yes`
    );
    if (response?.result?.permissions) {
      dispatch({
        type: SET_PERMISSIONS,
        payload: {
          permissions: response.result?.permissions,
        },
      });
    }
  } catch (error) {
    console.log(error);
    return;
  }
};

// LOGOUT
// eslint-disable-next-line
export const logout = (navigate) => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
  navigate("/");
};
