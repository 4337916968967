import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconContext } from "react-icons";

import {
  Drawer as MuiDrawer,
  Box,
  Stack,
  List,
  IconButton,
  ListSubheader,
} from "@mui/material";

import {
  IoSpeedometerOutline as DashboardIcon,
  // IoGridOutline as CollectionIcon,
} from "react-icons/io5";

import { RiUserLine as UserIcon } from "react-icons/ri";
import { FiSettings as ConfigurationIcon } from "react-icons/fi";
import {
  FaHandHoldingUsd as CollectionIcon,
  FaLuggageCart as SupplierIcon,
} from "react-icons/fa";
import { GiFarmTractor as FarmIcon } from "react-icons/gi";
import { BiTargetLock as InputMgtIcon } from "react-icons/bi";
import {
  CgMenuLeftAlt as MenuIcon,
  CgClose as CloseIcon,
} from "react-icons/cg";

import DraftsIcon from "@mui/icons-material/Drafts";

import CustomListItem from "./common/CustomListItem";
// import Can from "../utils/rbac/Can";
import UserAvatar from "./common/UserAvatar";

// import { defaultUser } from "../data/AuthUser";
import { theme } from "../context/ThemeProvider";

import paymentSVG from "../assets/PaymentIcon.svg";
import tradeBuzaLogo from "../assets/trade_buza_logo.png";

import { setCurrentDrawerMenu } from "../redux/actions/AppActions";
import { getAllPermission } from "../redux/actions/AuthActions";

const DrawerContent = ({ closeHandler }) => {
  // const [authenticatedUser, setAuthenticatedUser] = useState(defaultUser);
  const authenticatedUser = useSelector(
    (state) =>
      state.AuthReducer.userProfile ||
      JSON.parse(localStorage.getItem("user_profile"))
  );
  const rolePermissions = authenticatedUser?.myPermissions;
  const listSelected = useSelector((state) =>
    parseInt(state.AppReducer.menuSelected, 10)
  );

  const dispatch = useDispatch();
  // console.log(theme.palette.primary.main);
  const handleListItemClick = (index) => () => {
    dispatch(setCurrentDrawerMenu(index));
  };

  const imgLogo = JSON.parse(localStorage.getItem("user_profile"));
  const current_app = localStorage.getItem("current_app");

  useEffect(() => {
    dispatch(getAllPermission());
  }, [dispatch]);

  return (
    <Stack
      direction='column'
      alignItems='center'
      justifyContent='space-between'
      sx={{
        width: "100%",
        height: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
        color: "#fff",
        // px: 2,
      }}
    >
      <List
        sx={{ width: "100%" }}
        component='nav'
        aria-labelledby='nested-list-subheader'
        subheader={
          <ListSubheader
            component='div'
            id='nested-list-subheader'
            sx={{ padding: "1rem 10px" }}
          >
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
            >
              {imgLogo && (
                <img
                  src={`${
                    imgLogo?.tenant?.logo
                      ? imgLogo?.tenant?.logo
                      : tradeBuzaLogo
                  }`}
                  alt='tradebuza logo'
                  id='drawer-logo'
                />
              )}
              <IconContext.Provider
                value={{ size: "28px", color: `${theme.palette.primary.main}` }}
              >
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  onClick={() => {}}
                  edge='start'
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "block",
                    },
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  onClick={closeHandler}
                  edge='start'
                  sx={{
                    display: {
                      xs: "block",
                      sm: "block",
                      md: "none",
                    },
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </IconContext.Provider>
            </Stack>

            <Stack
              alignItems='center'
              justifyContent='center'
              sx={{ padding: "32px 0 40px" }}
            >
              <UserAvatar
                size='78px'
                fontSize='32px'
                firstname={authenticatedUser?.first_name || ""}
                lastname={authenticatedUser?.last_name || ""}
              />
            </Stack>
          </ListSubheader>
        }
      >
        <IconContext.Provider value={{ className: "drawer-icons" }}>
          {Object.keys(authenticatedUser).length > 0 ? (
            <React.Fragment>
              {current_app === "agro" && (
                <>
                  {rolePermissions.includes("can_view_dashboard") && (
                    <CustomListItem
                      icon={<DashboardIcon />}
                      text='Dashboard'
                      href='/dashboard'
                      selected={listSelected === 0}
                      onClick={handleListItemClick(0)}
                    />
                  )}

                  <CustomListItem
                    icon={<UserIcon />}
                    text='User Management'
                    selected={listSelected === 2}
                    onClick={handleListItemClick(2)}
                  >
                    <List component='div' disablePadding>
                      {rolePermissions.includes("can_view_user") && (
                        <CustomListItem
                          text='Manage Users'
                          href='/user-management/users'
                        />
                      )}
                      {rolePermissions.includes("can_view_farmer") && (
                        <CustomListItem
                          text='Manage Farmers'
                          href='/user-management/farmers'
                        />
                      )}

                      {rolePermissions.includes("can_view_agent") && (
                        <CustomListItem
                          text='Manage Sourcing Agents'
                          href='/user-management/sourcing-agents'
                        />
                      )}
                      {rolePermissions.includes("create_role") && (
                        <CustomListItem
                          text='Manage Roles'
                          href='/user-management/roles'
                        />
                      )}
                    </List>
                  </CustomListItem>

                  <CustomListItem
                    icon={<ConfigurationIcon />}
                    text='Configurations'
                    selected={listSelected === 3}
                    onClick={handleListItemClick(3)}
                  >
                    <List component='div' disablePadding>
                      {rolePermissions.includes("can_view_crop_profile") && (
                        <CustomListItem
                          text='Crop Profiles'
                          href='/configurations/crop-profile'
                        />
                      )}

                      {rolePermissions.includes("can_view_farm_season") && (
                        <CustomListItem
                          text='Farm Seasons'
                          href='/configurations/farm-seasons'
                        />
                      )}
                    </List>
                  </CustomListItem>

                  <CustomListItem
                    icon={<FarmIcon />}
                    text='Farm Management'
                    selected={listSelected === 4}
                    onClick={handleListItemClick(4)}
                  >
                    <List component='div' disablePadding>
                      {rolePermissions.includes("can_view_farm") && (
                        <CustomListItem
                          text='Manage Farm Lots'
                          href='/farm-management/farm-lots'
                        />
                      )}

                      {rolePermissions.includes("can_view_farm_cluster") && (
                        <CustomListItem
                          text='Manage Clusters'
                          href='/farm-management/clusters'
                        />
                      )}
                      {rolePermissions.includes(
                        "can_view_farm_sub_cluster"
                      ) && (
                        <CustomListItem
                          text='Manage Sub Clusters'
                          href='/farm-management/sub-clusters'
                        />
                      )}
                      {rolePermissions.includes("can_view_tenant") && (
                        <CustomListItem
                          text='Manage Farm Tenants'
                          href='/farm-management/tenants'
                        />
                      )}
                    </List>
                  </CustomListItem>

                  {rolePermissions.includes("can_view_input_tracking") && (
                    <CustomListItem
                      icon={<InputMgtIcon />}
                      text='Input Tracking'
                      href='/input-tracking'
                      selected={listSelected === 5}
                      onClick={handleListItemClick(5)}
                    />
                  )}

                  {rolePermissions.includes("can_view_farm_cluster") && (
                    <CustomListItem
                      icon={<FarmIcon />}
                      text='Farm Cluster Management'
                      href='/farm-cluster-management'
                      selected={listSelected === 11}
                      onClick={handleListItemClick(11)}
                    />
                  )}

                  {rolePermissions.includes("can_view_communication_setup") && (
                    <CustomListItem
                      icon={<DraftsIcon />}
                      text='Communication'
                      selected={listSelected === 6}
                      onClick={handleListItemClick(6)}
                    >
                      <List component='div' disablePadding>
                        {rolePermissions.includes(
                          "can_view_communication_setup"
                        ) && (
                          <CustomListItem
                            text='Communication Setup'
                            href='/communication/setup'
                          />
                        )}
                        {rolePermissions.includes("can_view_sms_messages") && (
                          <CustomListItem
                            text='Messages'
                            href='/communication/messages'
                          />
                        )}
                      </List>
                    </CustomListItem>
                  )}

                  {rolePermissions.includes("can_view_collection") && (
                    <CustomListItem
                      icon={<CollectionIcon />}
                      text='Collection'
                      selected={listSelected === 11}
                      onClick={handleListItemClick(11)}
                    >
                      {rolePermissions.includes("can_view_collection") && (
                        <List component='div' disablePadding>
                          <CustomListItem
                            text='Collections'
                            href='/collections'
                          />
                        </List>
                      )}
                    </CustomListItem>
                  )}

                  {rolePermissions.includes("can_view_farm_review") && (
                    <CustomListItem
                      icon={<FarmIcon />}
                      text='Farm Review'
                      selected={listSelected === 10}
                      onClick={handleListItemClick(10)}
                    >
                      <List component='div' disablePadding>
                        {rolePermissions.includes("can_view_farm_review") && (
                          <CustomListItem
                            text='Pending Review'
                            href='/farm-review/pending'
                          />
                        )}

                        {rolePermissions.includes("can_create_farm_review") && (
                          <CustomListItem
                            text='Submitted Review'
                            href='/farm-review/submitted'
                          />
                        )}
                      </List>
                    </CustomListItem>
                  )}

                  {rolePermissions.includes("can_view_transaction") && (
                    <CustomListItem
                      icon={<img src={paymentSVG} alt='' />}
                      text='Payment'
                      selected={listSelected === 7}
                      onClick={handleListItemClick(7)}
                    >
                      <List component='div' disablePadding>
                        {rolePermissions.includes("can_view_transaction") && (
                          <CustomListItem
                            text='Transactions'
                            href='/payment/transactions'
                          />
                        )}
                        {rolePermissions.includes("can_view_transaction") && (
                          <CustomListItem
                            text='Disbursement'
                            href='/payment/disbursments'
                          />
                        )}

                        {/* <CustomListItem
                      text='Collections'
                      href='/payment/collections'
                    /> */}
                      </List>
                    </CustomListItem>
                  )}

                  {rolePermissions.includes("create_role") && (
                    <CustomListItem
                      icon={<ConfigurationIcon />}
                      text='Settings'
                      selected={listSelected === 9}
                      onClick={handleListItemClick(9)}
                    >
                      <List component='div' disablePadding>
                        <CustomListItem text='App Settings' href='/settings' />
                        <CustomListItem
                          text='Countries'
                          href='/settings/countries'
                        />

                        <CustomListItem
                          text='Currencies'
                          href='/settings/currencies'
                        />
                        <CustomListItem
                          text='States / Regions'
                          href='/settings/states'
                        />
                      </List>
                    </CustomListItem>
                  )}
                </>
              )}

              {/* CAPTURE */}
              {current_app === "capture" && (
                <>
                  {rolePermissions.includes(
                    "can_view_dashboard_project_overview"
                  ) && (
                    <CustomListItem
                      icon={<DashboardIcon />}
                      text='Dashboard'
                      selected={listSelected === 1}
                      onClick={handleListItemClick(1)}
                    >
                      <List component='div' disablePadding>
                        {rolePermissions.includes(
                          "can_view_dashboard_project_overview"
                        ) && (
                          <CustomListItem
                            text='Programme Overview'
                            href='/dashboard-capture/overview'
                          />
                        )}

                        {rolePermissions.includes(
                          "can_view_dashboard_gps_overview"
                        ) && (
                          <CustomListItem
                            text='GPS Capture Overview'
                            href='/dashboard-capture/gps'
                          />
                        )}

                        {rolePermissions.includes(
                          "can_view_dashboard_gps_overview"
                        ) && (
                          <CustomListItem
                            text='GPS Captured Map'
                            href='/dashboard-capture/polygons'
                          />
                        )}
                      </List>
                    </CustomListItem>
                  )}

                  <CustomListItem
                    icon={<UserIcon />}
                    text='User Management'
                    selected={listSelected === 2}
                    onClick={handleListItemClick(2)}
                  >
                    <List component='div' disablePadding>
                      {rolePermissions.includes("can_view_user") && (
                        <CustomListItem
                          text='Manage Users'
                          href='/user-management/users'
                        />
                      )}
                      {rolePermissions.includes("can_view_farmer") && (
                        <CustomListItem
                          text='Manage Farmers'
                          href='/user-management/farmers'
                        />
                      )}

                      {rolePermissions.includes("can_view_agent") && (
                        <CustomListItem
                          text='Manage Sourcing Agents'
                          href='/user-management/sourcing-agents'
                        />
                      )}
                      {rolePermissions.includes("create_role") && (
                        <CustomListItem
                          text='Manage Roles'
                          href='/user-management/roles'
                        />
                      )}
                    </List>
                  </CustomListItem>

                  <CustomListItem
                    icon={<ConfigurationIcon />}
                    text='Configurations'
                    selected={listSelected === 3}
                    onClick={handleListItemClick(3)}
                  >
                    <List component='div' disablePadding>
                      {rolePermissions.includes("can_view_crop_profile") && (
                        <CustomListItem
                          text='Crop Profile'
                          href='/configurations/crop-profile'
                        />
                      )}
                      {/* {rolePermissions.includes("can_view_crop_calender") && (
                        <CustomListItem
                          text="Manage Crop Calendar"
                          href="/configurations/crop-calendar"
                        />
                      )} */}

                      {rolePermissions.includes("can_view_farm_season") && (
                        <CustomListItem
                          text='Farm Seasons'
                          href='/configurations/farm-seasons'
                        />
                      )}
                    </List>
                  </CustomListItem>

                  <CustomListItem
                    icon={<FarmIcon />}
                    text='Farm Management'
                    selected={listSelected === 4}
                    onClick={handleListItemClick(4)}
                  >
                    <List component='div' disablePadding>
                      {rolePermissions.includes("can_view_farm") && (
                        <CustomListItem
                          text='Manage Farm Lots'
                          href='/farm-management/farm-lots'
                        />
                      )}

                      {rolePermissions.includes("can_view_farm_cluster") && (
                        <CustomListItem
                          text='Manage Clusters'
                          href='/farm-management/clusters'
                        />
                      )}
                      {rolePermissions.includes(
                        "can_view_farm_sub_cluster"
                      ) && (
                        <CustomListItem
                          text='Manage Sub Clusters'
                          href='/farm-management/sub-clusters'
                        />
                      )}
                      {rolePermissions.includes("can_view_tenant") && (
                        <CustomListItem
                          text='Manage Farm Tenants'
                          href='/farm-management/tenants'
                        />
                      )}
                    </List>
                  </CustomListItem>

                  {rolePermissions.includes("can_view_farm_cluster") && (
                    <CustomListItem
                      icon={<FarmIcon />}
                      text='Farm Cluster Management'
                      href='/farm-cluster-management'
                      selected={listSelected === 11}
                      onClick={handleListItemClick(11)}
                    />
                  )}

                  {rolePermissions.includes("can_view_collection") && (
                    <CustomListItem
                      icon={<CollectionIcon />}
                      text='Collection'
                      selected={listSelected === 11}
                      onClick={handleListItemClick(11)}
                    >
                      {rolePermissions.includes("can_view_collection") && (
                        <List component='div' disablePadding>
                          <CustomListItem
                            text='Collections'
                            href='/collections'
                          />
                        </List>
                      )}
                    </CustomListItem>
                  )}

                  {rolePermissions.includes("can_view_farm_review") && (
                    <CustomListItem
                      icon={<FarmIcon />}
                      text='Farm Review'
                      selected={listSelected === 10}
                      onClick={handleListItemClick(10)}
                    >
                      <List component='div' disablePadding>
                        {rolePermissions.includes("can_view_farm_review") && (
                          <CustomListItem
                            text='Pending Review'
                            href='/farm-review/pending'
                          />
                        )}

                        {rolePermissions.includes("can_create_farm_review") && (
                          <CustomListItem
                            text='Submitted Review'
                            href='/farm-review/submitted'
                          />
                        )}
                      </List>
                    </CustomListItem>
                  )}
                </>
              )}

              {/* CROP GATE */}
              {current_app === "crop-gate" && (
                <>
                  <CustomListItem
                    icon={<DashboardIcon />}
                    text='Dashboard'
                    href='/dashboard-crop-gate/overview'
                    selected={listSelected === 12}
                    onClick={handleListItemClick(12)}
                  />

                  <CustomListItem
                    icon={<SupplierIcon />}
                    text='Supplier Management'
                    href='/crop-gate/supplier-management'
                    selected={listSelected === 16}
                    onClick={handleListItemClick(16)}
                  />

                  <CustomListItem
                    icon={<CollectionIcon />}
                    text='Collections'
                    href='/crop-gate/collections'
                    selected={listSelected === 13}
                    onClick={handleListItemClick(13)}
                  />

                  <CustomListItem
                    icon={<CollectionIcon />}
                    text='Cash Advance'
                    href='/crop-gate/cash-advance'
                    selected={listSelected === 14}
                    onClick={handleListItemClick(14)}
                  />

                </>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {[1, 2, 3, 4].map((item, index) => (
                <div key={index} className='flex flex-row mb-6 px-5'>
                  <div
                    className='lazy-loading p-3 mr-4'
                    style={{
                      borderRadius: "5px",
                      opacity: 0.8,
                      width: "20px",
                      height: "20px",
                    }}
                  ></div>
                  <div
                    className='lazy-loading p-3'
                    style={{ borderRadius: "5vh", opacity: 0.8, flexGrow: 1 }}
                  ></div>
                </div>
              ))}
            </React.Fragment>
          )}
        </IconContext.Provider>
      </List>

      {current_app === "crop-gate" && (
        <List
          sx={{
            width: "100%",
          }}
          component='nav'
          aria-labelledby='nested-list-subheader'
          subheader={
            <ListSubheader
              component='h2'
              id='nested-list-subheader'
              // sx={{ padding: "1rem 10px" }}
              sx={{
                padding: "1rem 0 0 15px",
                backgroundColor: "transparent",
                // color: "#fff",
                fontWeight: 700,
                fontSize: "14px",
              }}
            >
              YOUR ACCOUNT
            </ListSubheader>
          }
        >
          <IconContext.Provider value={{ className: "drawer-icons" }}>
            {Object.keys(authenticatedUser).length > 0 ? (
              current_app === "crop-gate" && (
                <React.Fragment>
                  <CustomListItem
                    icon={<UserIcon />}
                    text='User Management'
                    href='/crop-gate-user-management/users'
                    selected={listSelected === 15}
                    onClick={handleListItemClick(15)}
                  ></CustomListItem>
                </React.Fragment>
              )
            ) : (
              <React.Fragment>
                {[1, 2].map((item, index) => (
                  <div key={index} className='flex flex-row mb-6 px-5'>
                    <div
                      className='lazy-loading p-3 mr-4'
                      style={{
                        borderRadius: "5px",
                        opacity: 0.8,
                        width: "20px",
                        height: "20px",
                      }}
                    ></div>
                    <div
                      className='lazy-loading p-3'
                      style={{ borderRadius: "5vh", opacity: 0.8, flexGrow: 1 }}
                    ></div>
                  </div>
                ))}
              </React.Fragment>
            )}
          </IconContext.Provider>
        </List>
      )}
    </Stack>
  );
};

function Drawer({ isOpen, toggler }) {
  const drawerWidth = 305;
  return (
    <Box
      component='nav'
      id='main-drawer'
      sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
      aria-label='Navigation Options'
    >
      <MuiDrawer
        variant='temporary'
        open={isOpen}
        onClose={toggler}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "block", md: "none" },
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        {<DrawerContent closeHandler={toggler} />}
      </MuiDrawer>
      <MuiDrawer
        variant='permanent'
        open
        sx={{
          display: { xs: "none", sm: "none", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {<DrawerContent closeHandler={toggler} />}
      </MuiDrawer>
    </Box>
  );
}

export default Drawer;
