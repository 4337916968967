import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
  // Redirect
} from "react-router-dom";

import { ToastContainer as DefaultToastContainer } from "react-toastify";

// Import Contexts
import { theme, ThemeProvider } from "./context/ThemeProvider";

// Import Utility Components
// import Can from "./utils/rbac/Can";
import SuspenseFallback from "./components/SuspenseFallback";
import ErrorBoundary from "./components/ErrorBoundary";
import NoPage from "./components/UnknownPage";

// Other assets
import "react-toastify/dist/ReactToastify.min.css";
import "./App.css";

// Import Pages
import Dashboard from "./pages/Dashboard";
import MiniDrawer from "./components/MiniDrawer";
import { useDispatch, useSelector } from "react-redux";
import { getPermission } from "./redux/actions/AppActions";
import { getAllPermission } from "./redux/actions/AuthActions";

// Import Layout Components for Pages
const DashboardIndexSection = React.lazy(() =>
  import("./layouts/dashboard/Index")
);
// const Programme = React.lazy(() => import("./layouts/dashboard/Programme"));
const FarmAnalysis = React.lazy(() =>
  import("./layouts/dashboard/farmAnalysis/FarmAnalysis")
);
const FarmActivities = React.lazy(() =>
  import("./layouts/dashboard/FarmActivities/FarmActivities")
);
const AgentActivities = React.lazy(() =>
  import("./layouts/dashboard/agentActivities/AgentActivities")
);

const Profile = React.lazy(() => import("./layouts/dashboard/Profile/Profile"));

const Dvalco1 = React.lazy(() => import("./layouts/DashboardCapture/Dvalco1"));

const ProgrammeIndex = React.lazy(() =>
  import("./layouts/DashboardCapture/ProgrammeIndex")
);

const Polygons = React.lazy(() =>
  import("./layouts/DashboardCapture/Polygons")
);

const CropProfileSection = React.lazy(() =>
  import("./layouts/Configurations/CropProfile")
);

const ViewCropProfileSection = React.lazy(() =>
  import("./layouts/Configurations/ViewCropProfile")
);

const CropProfileDraftSection = React.lazy(() =>
  import("./layouts/Configurations/CropProfileDrafts")
);

const AddCropProfile = React.lazy(() =>
  import("./layouts/Configurations/AddCropProfile")
);

const ManageCropCategoriesSection = React.lazy(() =>
  import("./layouts/Configurations/ManageCategories")
);
const ViewCropCategory = React.lazy(() =>
  import("./layouts/Configurations/ViewCategory")
);
const AddCategory = React.lazy(() =>
  import("./layouts/Configurations/AddCategory")
);

const EditCategory = React.lazy(() =>
  import("./layouts/Configurations/EditCategory")
);

const FarmSeasonSection = React.lazy(() =>
  import("./layouts/Configurations/FarmSeasons")
);

const ViewSeason = React.lazy(() =>
  import("./layouts/Configurations/ViewSeason")
);

const Roles = React.lazy(() => import("./layouts/userManagement/Roles"));
const AddRole = React.lazy(() => import("./layouts/userManagement/AddRole"));
const EditRole = React.lazy(() => import("./layouts/userManagement/EditRole"));

const Settings = React.lazy(() => import("./layouts/settings/Settings"));
const Countries = React.lazy(() => import("./layouts/settings/Countries"));
const AddCountry = React.lazy(() => import("./layouts/settings/AddCountry"));
const Currencies = React.lazy(() => import("./layouts/settings/Currencies"));
const AddCurrency = React.lazy(() => import("./layouts/settings/AddCurrency"));
// const AddState = React.lazy(() => import("./layouts/settings/AddState"));
// const States = React.lazy(() => import("./layouts/settings/States"));

// const Utils = React.lazy(() => import("./layouts/utils/Utils"));

// const Jobs = React.lazy(() => import("./layouts/jobs/Jobs"));

const Users = React.lazy(() => import("./layouts/userManagement/Users"));
const AddUser = React.lazy(() => import("./layouts/userManagement/AddUser"));
const EditUser = React.lazy(() => import("./layouts/userManagement/EditUser"));

const Farmers = React.lazy(() => import("./layouts/userManagement/Farmers"));
const AddFarmer = React.lazy(() =>
  import("./layouts/userManagement/AddFarmer")
);
const EditFarmer = React.lazy(() =>
  import("./layouts/userManagement/EditFarmer")
);
const Exports = React.lazy(() => import("./layouts/userManagement/Exports"));

const SourcingAgents = React.lazy(() =>
  import("./layouts/userManagement/SourcingAgents")
);
const AddSourcingAgent = React.lazy(() =>
  import("./layouts/userManagement/AddSourcingAgent")
);

const FarmLots = React.lazy(() =>
  import("./layouts/FarmManagement/ManageFarmLots")
);

const Tenants = React.lazy(() => import("./layouts/FarmManagement/Tenants"));

const AddTenant = React.lazy(() =>
  import("./layouts/FarmManagement/AddTenant")
);

const EditTenants = React.lazy(() =>
  import("./layouts/FarmManagement/EditTenant")
);

const ViewFarmUnit = React.lazy(() =>
  import("./layouts/FarmManagement/ViewFarmUnit")
);

const EditFarmUnit = React.lazy(() =>
  import("./layouts/FarmManagement/EditFarmUnit")
);

const AddFarmUnit = React.lazy(() =>
  import("./layouts/FarmManagement/AddFarmUnit")
);

const FarmClusters = React.lazy(() =>
  import("./layouts/FarmManagement/ManageClusters")
);
const ViewFarmClusters = React.lazy(() =>
  import("./layouts/FarmManagement/ViewCluster")
);
const FarmSubClusters = React.lazy(() =>
  import("./layouts/FarmManagement/ManageSubClusters")
);
const ViewSubclusterLots = React.lazy(() =>
  import("./layouts/FarmManagement/ViewSubCluster")
);

const InputTracking = React.lazy(() => import("./layouts/InputTracking/Input"));

const CommunicationSetup = React.lazy(() =>
  import("./layouts/Communication/CommunicationSetup")
);
const Messages = React.lazy(() => import("./layouts/Communication/Messages"));

const Collections = React.lazy(() =>
  import("./layouts/Collections/Collections")
);

const PendingReview = React.lazy(() =>
  import("./layouts/FarmReview/PendingReview")
);

const SubmittedReview = React.lazy(() =>
  import("./layouts/FarmReview/SubmittedReview")
);

const FarmClusterManagement = React.lazy(() =>
  import("./layouts/FarmClusterManagement/FarmClusterManagement")
);

const PaymentCollections = React.lazy(() =>
  import("./layouts/Payment/Collections")
);
const PaymentTransactions = React.lazy(() =>
  import("./layouts/Payment/Transactions")
);
const PaymentDisbursments = React.lazy(() =>
  import("./layouts/Payment/Disbursment")
);
const SendMoney = React.lazy(() => import("./layouts/Payment/SendMoney"));

const Login = React.lazy(() => import("./pages/Login"));

// CROP GATE
const DashboardCropGate = React.lazy(() =>
  import("./layouts/DashboardCropGate/DashboardCropGate")
);
const CropGateUsers = React.lazy(() =>
  import("./layouts/UserManagementCropGate/Users")
);

const CropGateSuppliers = React.lazy(() =>
  import("./layouts/SupplierManagement/Suppliers")
);

const CropGateViewSupplier = React.lazy(() =>
  import("./layouts/SupplierManagement/ViewSupplier")
);

const CropGateAddSupplier = React.lazy(() =>
  import("./layouts/SupplierManagement/AddSupplier")
);

const CropGateEditSupplier = React.lazy(() =>
  import("./layouts/SupplierManagement/EditSupplier")
);

const CropGateSupplies = React.lazy(() =>
  import("./layouts/Collections[CropGate]/Supplies")
);

const CropGateViewSupplies = React.lazy(() =>
  import("./layouts/Collections[CropGate]/ViewSupplies")
);

const AddNewUser = React.lazy(() =>
  import("./layouts/UserManagementCropGate/AddNewUser")
);
const UserDetails = React.lazy(() =>
  import("./layouts/UserManagementCropGate/UserDetails")
);

const CashAdvance = React.lazy(() =>
  import("./layouts/CashAdvance/CashAdvance")
);

const CashAdvanceSupplierDetails = React.lazy(() =>
  import("./layouts/CashAdvance/CashAdvanceSupplierDetails")
);

// const EditUser = React.lazy(() => import("./layouts/userManagement/EditUser"));

// const Test = React.lazy(() => import("./pages/Test"));

// create a default container so we can override the styles
const ToastContainer = (props) => (
  <DefaultToastContainer style={{ zIndex: "1900" }} {...props} />
);

function App() {
  // const permissions = useSelector((state) => state.AuthReducer?.permissions);

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <React.Suspense fallback={<SuspenseFallback />}>
          <Router>
            <Switch>
              <Route exact path='/' element={<Login />} />

              {/* <Route exact path="/test" element={<Test />} /> */}

              {/* DASHBOARD */}
              <Route path='/dashboard' element={<Dashboard />}>
                <Route index element={<DashboardIndexSection />} />
                <Route
                  path='edit-profile'
                  element={<DashboardIndexSection />}
                />
                <Route path='farm-analysis' element={<FarmAnalysis />} />
                <Route path='farm-activities' element={<FarmActivities />} />
                <Route path='agent-activities' element={<AgentActivities />} />
              </Route>

              {/* PROFILE */}
              <Route path='/profile' element={<Dashboard />}>
                <Route index element={<Profile />} />
              </Route>

              {/* CAPTURE DASHBOARD */}
              <Route path='/dashboard-capture' element={<Dashboard />}>
                <Route index element={<DashboardIndexSection />} />
                <Route path='gps' element={<Dvalco1 />} />
                <Route path='overview' element={<ProgrammeIndex />} />
                <Route path='polygons' element={<Polygons />} />
              </Route>

              {/* CONFIGURATIONS */}
              <Route path='/configurations' element={<Dashboard />}>
                <Route index element={<DashboardIndexSection />} />
                <Route path='crop-profile' element={<CropProfileSection />} />
                <Route
                  path='crop-profile/drafts'
                  element={<CropProfileDraftSection />}
                />
                <Route
                  path='crop-profile/:cropId'
                  element={<ViewCropProfileSection />}
                />
                <Route
                  path='crop-profiles/add-crop'
                  element={<AddCropProfile />}
                />
                <Route
                  path='crop-profile/:cropId/add-category'
                  element={<AddCategory />}
                />
                <Route
                  path='crop-profile/:cropId/edit-category/:categoryId'
                  element={<EditCategory />}
                />
                <Route
                  path='crop-profile/:cropId/category/:categoryId'
                  element={<ViewCropCategory />}
                />
                <Route
                  path='crop-calendar/manage-categories'
                  element={<ManageCropCategoriesSection />}
                />

                <Route path='farm-seasons' element={<FarmSeasonSection />} />
                <Route
                  path='farm-seasons/:seasonDate'
                  element={<ViewSeason />}
                />
              </Route>

              {/* USER MANAGEMENT */}
              <Route path='/user-management' element={<Dashboard />}>
                <Route index element={<DashboardIndexSection />} />
                <Route path='users' element={<Users />} />
                <Route path='add-user' element={<AddUser />} />
                <Route path='edit-user/:userId' element={<EditUser />} />
                <Route path='roles' element={<Roles />} />
                <Route path='add-role' element={<AddRole />} />
                <Route path='edit-role/:roleId' element={<EditRole />} />
                <Route path='sourcing-agents' element={<SourcingAgents />} />
                <Route
                  path='add-sourcing-agent'
                  element={<AddSourcingAgent />}
                />
                <Route path='farmers' element={<Farmers />} />
                <Route path='farmers/exports' element={<Exports />} />
                <Route path='add-farmer' element={<AddFarmer />} />
                <Route path='edit-farmer/:farmerId' element={<EditFarmer />} />
              </Route>

              {/* FARM MANAGEMENT */}
              <Route path='/farm-management' element={<Dashboard />}>
                <Route index element={<DashboardIndexSection />} />
                <Route path='farm-lots' element={<FarmLots />} />
                <Route path='tenants' element={<Tenants />} />
                <Route path='add-tenant' element={<AddTenant />} />
                <Route path='edit-tenant/:tenantId' element={<EditTenants />} />
                <Route path='add-farm-unit' element={<AddFarmUnit />} />
                <Route
                  path='edit-farm-unit/:farmId'
                  element={<EditFarmUnit />}
                />
                <Route
                  path='view-farm-unit/:farmId'
                  element={<ViewFarmUnit />}
                />
                <Route path='clusters' element={<FarmClusters />} />
                <Route
                  path='view-farm-unit/:farmId'
                  element={<ViewFarmUnit />}
                />
                <Route path='clusters' element={<FarmClusters />} />
                <Route
                  path='clusters/:clusterId'
                  element={<ViewFarmClusters />}
                />
                <Route path='sub-clusters' element={<FarmSubClusters />} />
                <Route
                  path='sub-clusters/:subClusterId'
                  element={<ViewSubclusterLots />}
                />
              </Route>

              {/* INPUT TRACKING */}
              <Route path='/input-tracking' element={<Dashboard />}>
                <Route index element={<InputTracking />} />
              </Route>

              {/* COMMUNICATION */}
              <Route path='/communication' element={<Dashboard />}>
                <Route index element={<DashboardIndexSection />} />
                <Route path='setup' element={<CommunicationSetup />} />
                <Route path='messages' element={<Messages />} />
              </Route>

              {/* COLLECTIONS */}
              <Route path='/collections' element={<Dashboard />}>
                <Route index element={<Collections />} />
              </Route>

              {/* PendingReview */}
              <Route path='/farm-review' element={<Dashboard />}>
                <Route path='pending' element={<PendingReview />} />
                <Route path='submitted' element={<SubmittedReview />} />
              </Route>

              {/* farm-cluster-management */}
              <Route path='/farm-cluster-management' element={<Dashboard />}>
                <Route index element={<FarmClusterManagement />} />
              </Route>

              {/* Settings */}
              <Route path='/settings' element={<Dashboard />}>
                <Route index element={<Settings />} />
                <Route path='countries' element={<Countries />} />
                <Route path='add-country' element={<AddCountry />} />
                <Route path='currencies' element={<Currencies />} />
                <Route path='add-currency' element={<AddCurrency />} />
              </Route>

              {/* PAYMENT */}
              <Route path='/payment' element={<Dashboard />}>
                <Route index element={<DashboardIndexSection />} />
                <Route path='transactions' element={<PaymentTransactions />} />
                <Route path='collections' element={<PaymentCollections />} />
                <Route path='disbursments' element={<PaymentDisbursments />} />
                <Route path='send-money' element={<SendMoney />} />
              </Route>

              <Route path='/dashboard2' element={<MiniDrawer />} />

              {/* CROP GATE */}
              {/* DASHBOARD */}
              <Route path='/dashboard-crop-gate' element={<Dashboard />}>
                <Route index element={<DashboardIndexSection />} />
                <Route path='overview' element={<DashboardCropGate />} />
              </Route>
              {/* USER MANAGEMENT CROP GATE */}
              <Route path='/crop-gate-user-management' element={<Dashboard />}>
                <Route index element={<DashboardIndexSection />} />
                <Route path='users' element={<CropGateUsers />} />
                <Route path='add-user' element={<AddNewUser />} />
                <Route path='user/:userId' element={<UserDetails />} />
              </Route>

              {/* CONFIGURATIONS */}
              <Route path='/crop-gate' element={<Dashboard />}>
                <Route index element={<DashboardIndexSection />} />
                <Route path='cash-advance' element={<CashAdvance />} />
                <Route
                  path='cash-advance/supplier/:id'
                  element={<CashAdvanceSupplierDetails />}
                />
                {/* 
                <Route
                  path="crop-profile/:cropId"
                  element={<ViewCropProfileSection />}
                />
                <Route
                  path="crop-profiles/add-crop"
                  element={<AddCropProfile />}
                />
                <Route
                  path="crop-profile/:cropId/add-category"
                  element={<AddCategory />}
                />
                <Route
                  path="crop-profile/:cropId/edit-category/:categoryId"
                  element={<EditCategory />}
                />
                <Route
                  path="crop-profile/:cropId/category/:categoryId"
                  element={<ViewCropCategory />}
                />
                <Route
                  path="crop-calendar/manage-categories"
                  element={<ManageCropCategoriesSection />}
                />

                <Route path="farm-seasons" element={<FarmSeasonSection />} />
                <Route
                  path="farm-seasons/:seasonDate"
                  element={<ViewSeason />}
                /> */}
              </Route>
              {/* COLLECTIONS CROP GATE */}
              <Route path='/crop-gate' element={<Dashboard />}>
                <Route
                  path='supplier-management'
                  element={<CropGateSuppliers />}
                />
                <Route
                  path='supplier-management/add-supplier'
                  element={<CropGateAddSupplier />}
                />
                <Route
                  path='supplier-management/update-supplier/:supplierId'
                  element={<CropGateEditSupplier />}
                />
                <Route
                  path='supplier-management/suppliers/:supplierId'
                  element={<CropGateViewSupplier />}
                />
                <Route path='collections' element={<CropGateSupplies />} />
                <Route
                  path='collections/supplies/:supplyId'
                  element={<CropGateViewSupplies />}
                />
              </Route>

              <Route path='*' element={<NoPage />} />
            </Switch>
          </Router>
          <ToastContainer />
        </React.Suspense>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
