import {
  GET_SMS,
  GET_SMS_SUCCESS,
  GET_SMS_FAIL,
} from "../types/CommunicationsTypes";

const initialState = {
  messages: {
    data: [],
    loading: false,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SMS:
      return {
        ...state,
        messages: {
          ...state.messages,
          loading: true,
        },
      };

    case GET_SMS_SUCCESS:
      console.log(payload);
      return {
        ...state,
        messages: {
          ...state.messages,
          data: payload,
          loading: false,
        },
      };

    case GET_SMS_FAIL:
      return {
        ...state,
        messages: {
          ...state.messages,
          loading: false,
        },
      };

    default:
      return state;
  }
}
